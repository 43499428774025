import WomanSilhouette from "../images/silhouette2.png"
import CockSill from "../images/cocksill2.png"
import FootSill from "../images/footsill.png"
import TeaCan from "../images/teacan.png"
import CokeCan from "../images/cokecan.png"
import MonsterCan from "../images/monstercan.png"
import CokeBottle from "../images/cokebottle.png"
import EmpireStateBuilding from "../images/empireStateBuilding.png"
import BurjKhalifa from "../images/burfKhalifa.png"
import StatueOfLiberty from "../images/statueOfLiberty.png"
import Circle from "../images/circle.png"
import UpperBodySilhoutte from "../images/silhouetteUpperBody.png"

import styled from "styled-components"


// import CockShape2 from "../images/CockShape2.png"
// import CockShape3 from "../images/CockShape3.png"

export function SecondDropdown(value,onChange) {
    return <div> 
        <Sel value={value} onChange={(e) => console.log(e.target.value)}>
            <option value={{ size : 150, image : WomanSilhouette,width: 41.4 * 1.384}}>Her body</option>
            <option value={{ size : 30, image : FootSill,width : 12.6}}>One of her feet</option>
            <option value={{ size : 15, image : CockSill, width : 3.5}}>Her cock</option>
            <option value={{ size : 12.2682, image : CokeCan, width : 6.6}}>Soda Can</option>
            <option value={{ size : 16.75, image : MonsterCan, width : 6.6}}>Monster 16oz Can</option>
            <option value={{ size : 19.304, image : TeaCan, width : 7.23}}>Arizona Tea Can</option>
            <option value={{ size : 31.75, image : CokeBottle, width : 11.43}}>2 Liter Bottle</option>
            <option value={{ size : 6300, image : StatueOfLiberty, width : 1070}}>Statue of Liberty</option>
            <option value={{ size : 38100, image : EmpireStateBuilding, width : 12900}}>Empire State Building</option>
            <option value={{ size : 82800, image : BurjKhalifa, width : 17200}}>Burj Khalifa</option>
        </Sel>
    </div>
}

export function personObjects(centimeters,factor,footCM,cockLength,cockWidth,personWidth, name,softCockLength,softCockWidth) {
  return [
    { value : {size : centimeters * factor, image : WomanSilhouette,width : personWidth * 1.384,showWidth : false,label : `${name}`},label : `${name}`},
    { value : {size : footCM * factor , image : FootSill,width : (footCM * factor) / 2.365,showWidth : false,label : `${name}'s foot`},label : `${name}'s foot`},
    { value : {size : cockLength * factor, image : CockSill,width : cockWidth * factor,showWidth : true,label : `${name}'s hard cock`},label : `${name}'s hard cock`},
    { value : {size : softCockLength * factor, image : CockSill,width : softCockWidth * factor,showWidth : true,label : `${name}'s soft cock`},label : `${name}'s soft cock`},
  ] 
}

export function fulllistObjects(centimeters,factor,footCM,cockLength,cockWidth,personWidth, name,softCockLength,softCockWidth) {
  return [
    { value : {size : centimeters * factor, image : WomanSilhouette,width : personWidth * 1.384,showWidth : false, key: "mainPlayer",label : `${name}`,category: "Your character"},label : `${name}`,category: "Your character"},
    { value : {size : footCM * factor , image : FootSill,width : (footCM * factor) / 2.365,showWidth : false, key: "foot",label : `${name}'s foot`,category: "Your character"},label : `${name}'s foot`,category: "Your character"},
    { value : {size : cockLength * factor, image : CockSill,width : cockWidth * factor,showWidth : true, key: "hardcock",label : `${name}'s hard cock`,category: "Your character"},label : `${name}'s hard cock`,category: "Your character"},
    { value : {size : softCockLength * factor, image : CockSill,width : softCockWidth * factor,showWidth : true, key: "softcock",label : `${name}'s soft cock`,category: "Your character"},label : `${name}'s soft cock`,category: "Your character"},
    { value: { size : 150, image : WomanSilhouette,width: 41.4 * 1.384, label: 'Average Woman', key: "avgbody",showWidth : false,category: "Averages"}, label: 'Body', key: "avgbody",category: "Averages"},
    { value: { size : 85.3, image : UpperBodySilhoutte, label: "Upper Body", width : 41.4 * 1.384,showWidth : false, key: "upperbody",category: "Averages"}, label: "Upper Body", key: "avgupper",category: "Averages"},
    { value: { size : 30, image : FootSill, label: 'Feet',width : 12.6, key: "avgfeet",showWidth : false,category: "Averages"}, label: 'Feet' , key: "avgfeet",category: "Averages"},
    { value: { size : 13, image : CockSill, label: "Average Penis", width : 3.5,showWidth : true, key: "avgdick",category: "Averages"}, label: "Average Penis", key: "avgdick",category: "Averages"},
    { value: { size : 12.2682, image : CokeCan, label: 'Soda Can', width : 6.6,showWidth : true, key: "sodacan",category: "Objects"}, label: 'Soda Can', key: "sodacan",category: "Objects" },
    { value: { size : 16.75, image : MonsterCan, label: 'Monster 16oz Can', width : 6.6,showWidth : true, key: "monstercan",category: "Objects"}, label: 'Monster 16oz Can', key: "monstercan",category: "Objects"  },
    { value: { size : 19.304, image : TeaCan, label: 'Arizona Tea Can', width : 7.23,showWidth : true, key: "arizonateacan",category: "Objects"}, label: 'Arizona Tea Can', key: "arizonateacan",category: "Objects"  },
    { value: { size : 31.75, image : CokeBottle, label: '2 Liter Bottle', width : 11.43,showWidth : true, key: "2liter",category: "Objects"}, label: '2 Liter Bottle', key: "2liter",category: "Objects" },
    { value: { size : 6300, image : StatueOfLiberty, label: 'Statue of Liberty', width : 1070,showWidth : true, key: "statueofLiberty",category: "Buildings"}, label: 'Statue of Liberty', key: "statueofLiberty",category: "Buildings" },
    { value: { size : 38100, image : EmpireStateBuilding, label: 'Empire State Building', width : 12900,showWidth : false, key: "empirestate",category: "Buildings"}, label: 'Empire State Building', key: "empirestate",category: "Buildings"},
    { value: { size : 82800, image : BurjKhalifa, label: "Burj Khalifa", width : 17200,showWidth : false, key: "khalifa",category: "Buildings"}, label: "Burj Khalifa", key: "khalifa",category: "Buildings"},
    { value: { size : 347480000, image : Circle, label: "Moon", width: 347480000,showWidth : false,key: "Moon",category: "Celestial"}, label: "Moon",key: "Moon",category: "Celestial"},
    { value: { size : 1274200000, image : Circle, label: "Earth", width: 1274200000,showWidth : false,key: "Earth",category: "Celestial"}, label: "Earth",key: "Earth",category: "Celestial"},
    { value: { size : 13982000000, image : Circle, label: "Jupiter", width: 13982000000,showWidth : false,key: "Jupiter",category: "Celestial"}, label: "Jupiter",key: "Jupiter",category: "Celestial"},
    { value: { size : 139270000000, image : Circle, label: "Sun", width: 139270000000,showWidth : false,key: "Sun",category: "Celestial"}, label: "Sun",key: "Sun",category: "Celestial"},
  ] 
}

const sizeObjects = [
    { value: { size : 150, image : WomanSilhouette,width: 41.4 * 1.384, label: 'Average Woman', key: "avgbody",showWidth : false}, label: 'Body', key: "avgbody"},
    { value: { size : 85.3, image : UpperBodySilhoutte, label: "Upper Body", width : 41.4 * 1.384,showWidth : false}, label: "Upper Body", key: "avgupper"},
    { value: { size : 30, image : FootSill, label: 'Feet',width : 12.6,showWidth : false}, label: 'Feet' , key: "avgfeet"},
    { value: { size : 13, image : CockSill, label: "Average Penis", width : 3.5,showWidth : true}, label: "Average Penis", key: "avgdick"},
    { value: { size : 12.2682, image : CokeCan, label: 'Soda Can', width : 6.6,showWidth : true}, label: 'Soda Can', key: "sodacan"},
    { value: { size : 16.75, image : MonsterCan, label: 'Monster 16oz Can', width : 6.6,showWidth : true}, label: 'Monster 16oz Can', key: "monstercan" },
    { value: { size : 19.304, image : TeaCan, label: 'Arizona Tea Can', width : 7.23,showWidth : true}, label: 'Arizona Tea Can', key: "arizonateacan" },
    { value: { size : 31.75, image : CokeBottle, label: '2 Liter Bottle', width : 11.43,showWidth : true}, label: '2 Liter Bottle', key: "2liter" },
    { value: { size : 6300, image : StatueOfLiberty, label: 'Statue of Liberty', width : 1070,showWidth : true}, label: 'Statue of Liberty', key: "statueofLiberty" },
    { value: { size : 38100, image : EmpireStateBuilding, label: 'Empire State Building', width : 12900,showWidth : false}, label: 'Empire State Building', key: "empirestate" },
    { value: { size : 82800, image : BurjKhalifa, label: "Burj Khalifa", width : 17200,showWidth : false}, label: "Burj Khalifa", key: "khalifa"},
    { value: { size : 347480000, image : Circle, label: "Moon", width: 347480000,showWidth : false}, label: "Moon",key: "Moon"},
    { value: { size : 1274200000, image : Circle, label: "Earth", width: 1274200000,showWidth : false}, label: "Earth",key: "Earth"},
    { value: { size : 13982000000, image : Circle, label: "Jupiter", width: 13982000000,showWidth : false}, label: "Jupiter",key: "Jupiter"},
    { value: { size : 139270000000, image : Circle, label: "Sun", width: 139270000000,showWidth : false}, label: "Sun",key: "Sun"}
  ];

const Sel = styled.select`
  margin: 10px;
  height:100%;
  padding: 10px;
  font-size: 1.5rem;
  width: 16rem;
`


export default sizeObjects;