import React, { useState } from "react";
import styled from "styled-components";


const DropDownContainer = styled("div")`
  width: 10.5em;
  margin: 0 auto;
`;

const DropDownHeader = styled("div")`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: #3faffa;
  background: #ffffff;
`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
`;

export default function CustomDropdown({value,setValue,options}) {
    const [isOpen, setIsOpen] = useState(false);
    
    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = value => () => {
        setValue(value.value);
        setIsOpen(false);
    };
    let valueLabel = ""
    for(let i = 0;i < options.length;i++){
        if(options[i].value === value) {
            valueLabel = options[i].label;
        }
    }

    return <DropDownContainer>
        <DropDownHeader onClick={toggling}>{valueLabel}</DropDownHeader>
        {isOpen && (
            <DropDownListContainer>
                <DropDownList>
                    {options.map(option => (
                        <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                        {option.label}
                        </ListItem>
                    ))}
                </DropDownList>
            </DropDownListContainer> )}
    </DropDownContainer>
}