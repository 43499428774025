import MetricVisualizer from "./metricVisualizer";
import styled from "styled-components";
import { useState } from "react";
import MetricMagnitudeSelector from "./magnitudeMetric";
import SimpleInput from "./SimpleInput";

import Button from "./button";
import Switch from "react-switch";

export default function SizeComparisonObject({object,setObject,objects,scale,imperial,showOptions,index,setCustomPropeties,showMeasurements,categories,removeObject}) {
    const [selected,setSelected] = useState(0);
    const [objectCustomTitle,setObjectCustomTitle] = useState("");
    const [custom,setCustom] = useState(false);
    const [category,setCategory] = useState(object.category)

    let widthScale = scale / (object.size / object.width);
   
    let options = []

    for(let i = 0;i < objects.length;i++) {
        if(objects[i].category === category) {
            let newOption = objects[i];
            newOption.index = i;
            options.push(newOption);
        }
    }

    function handleSelect(e) {
        setObject(objects[e].value,index);
        setSelected(e);
    }

    function handleCustomLength(e) {
        let os = object
        os.size = e
        setCustomPropeties(os,true,index);
    }

    function handleCustomWidth(e) {
        let os = object
        os.width = e
        setCustomPropeties(os,true,index);
    }

    return <Parent>
        {showOptions &&
            <div>
                <Button onClick={() => removeObject(index)}> Remove </Button>
                <div>
                    <label>
                        <span>Custom Size</span> <br/>
                        <Switch onChange={() => setCustom(!custom)} checked={custom} />
                    </label>
                </div>
                {custom ? 
                    <div> 
                        <span> Length: <MetricMagnitudeSelector imperialOption={imperial} currentValue ={object.size} set={handleCustomLength}/></span>
                        <span> Width: <MetricMagnitudeSelector imperialOption={imperial} currentValue ={object.width} set={handleCustomWidth}/></span>
                        <span> Title: <SimpleInput type="text" value={objectCustomTitle} onChange={e => setObjectCustomTitle(e.target.value)}/></span>
                    </div>
                : 
                    <div>
                        <Sel value={category} onChange={(e) => setCategory(e.target.value)}>
                            {categories.map((item,index) => <option value={item} key={index}> {item} </option>)}
                        </Sel> <br/>
                        <Sel value={selected} onChange={(e) => handleSelect(e.target.value)}>
                            {options.map((item,index) => <option value={item.index} key={index}>{item.label}</option>)}
                        </Sel> 
                    </div>
                }
            </div>
        }
        {objectCustomTitle !== "" 
            ? <span>{objectCustomTitle} <br/> </span> 
            : <span>{object.label} <br/> </span> 
        }
        {showMeasurements &&
            <div>
        <MetricVisualizer value={object.size} imperial={imperial}/> <br/>
        {object.showWidth &&
        <span><MetricVisualizer value={object.width} imperial={imperial}/> <br/> </span>
        }
        </div>}
        <ItemContainer side={"row"}>
            <Item scale={scale} width={widthScale} src={object.image} side={"flex"}></Item>
        </ItemContainer >
</Parent>
}

const Item = styled.img`
    height: ${props => props.scale}vw;
    width: ${props => props.width}vw;
    
    
    @media(max-width: 800px) {
        height: ${props => props.scale * 1}vw;
        width: ${props => props.width * 1}vw;
    }
`

const Sel = styled.select`
    margin: 10px;
    height:100%;
    padding: 10px;
    font-size: 1.5rem;
    width: 16rem;
`

const Parent = styled.div`
    background-color: #c6d8f1;
    border-radius:16px;
    padding:4px;
    margin:4px;
`

const ItemContainer = styled.div`
    
`