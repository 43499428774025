import React, {useState} from 'react';

import SimpleMetrics from './components/ResultCards/SimpleMetrics';
import NormalManComparison from './components/ResultCards/NormalMan';
import FutaStats from './components/ResultCards/Futa';
import Forces from './components/ResultCards/Forces';
import Keypoints from './components/ResultCards/Keypoints';
import Movement from './components/ResultCards/Movement';
import FeetStats from './components/ResultCards/Feet';

import VisualComparison from './components/VisualDifference';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Grid from './components/grid.js';
import Card from './components/card';
import Toggle from 'react-styled-toggle';
import MuskResults from './components/ResultCards/MuskRange';
import LiquidVisualizer from './components/liquidVisualizer';


function Results({beforeCentimeters,factor,beforeHips,beforeWeight,beforeLift,futa,beforeCockLength,beforeCockWidth,beforeCumProduction,beforeFeetSize,beforeMuskRange,name,softCockLength,softCockWidth,gender}) {
    let afterCentimeters = beforeCentimeters * factor
    
    let factor170 = beforeCentimeters  / 170
    let factor120 = beforeWeight / 120
    let headHeight = afterCentimeters/7
    let headWidth = headHeight*(2/3)
    let weight = beforeWeight * factor ** 3
    let lift = beforeLift * factor ** 3
    
    
    let subHipstoHead = ( ( (beforeHips / 81.28) -1 ) * 1.5 )
    let hipsToHeadWidthRatio = ( 2 + subHipstoHead)
    let shoulderWidth = headWidth*2.9
    let hips = headWidth * hipsToHeadWidthRatio

    //let cockLength = beforeCockLength * factor;
    //let cockWidth = beforeCockWidth * factor;
    //let cockradius = cockWidth / 2;
    //let cockvolume =  3.141592 * Math.pow(cockradius,2) * cockLength;
    //let cockweight = cockvolume * 1.06;
    //let CumProduction = beforeCumProduction * Math.pow(factor,3);

    const [imperial,setImperial] = useState(false);

    return <Tabs>
        <Card>
         <b>After she grows:</b>
            <TabList>
                <Tab>Data</Tab>
                <Tab>Visual</Tab>
                <Tab>Liquid</Tab>
                <Tab>Credits</Tab>
            </TabList>
        <TabPanel>
            <span> <Toggle defaultChecked={imperial} onChange={(e) => setImperial(!imperial)}/> Imperial Units</span>
            <Grid>
                <SimpleMetrics factor={factor} afterCentimeters={afterCentimeters} headWidth={headWidth} shoulderWidth={shoulderWidth} hips={hips} weight={weight} lift={lift} imperial={imperial} gender={gender}/>
                <NormalManComparison factor={factor} imperial={imperial} gender={gender}/>
                <Keypoints factor={factor} headHeight={headHeight} imperial={imperial} gender={gender}/>
                <FeetStats factor={factor} beforeFeetSize={beforeFeetSize} imperial={imperial} gender={gender}/>
                {(gender === 0 || gender === 2) &&
                <FutaStats factor={factor} futa={futa} beforeCockLength={beforeCockLength} beforeCockWidth={beforeCockWidth} beforeCumProduction={beforeCumProduction} imperial={imperial} softCockLength={softCockLength}softCockWidth={softCockWidth} gender={gender}/>
                }
                <Forces factor={factor} factor120={factor120} beforeWeight={beforeWeight} weight={weight} imperial={imperial} gender={gender}/>
                <Movement factor={factor} factor170={factor170} imperial={imperial} gender={gender}/>
                <MuskResults musk={beforeMuskRange} factor={factor} imperial={imperial} gender={gender}/>
                
            </Grid>
        </TabPanel>
        <TabPanel>
            <VisualComparison factor={factor} personWidth={shoulderWidth} cockLength={beforeCockLength} centimeters={beforeCentimeters} footCM={beforeFeetSize} cockWidth={beforeCockWidth} name={name} softCockLength={softCockLength} softCockWidth={softCockWidth} gender={gender}/>
        </TabPanel>
        <TabPanel>
            <LiquidVisualizer />
        </TabPanel>
        <TabPanel>
            Author: CEOJeanne / Jahi the Jackal <br/>
            Discord: Jahi#0001 <br/>
            If you find any issue on the calculator please contact me! <br/>
            If you enjoy using the calculator, consider <a href="https://ko-fi.com/U7U1P8UM"> donating to it's development! </a> <br/>
        </TabPanel>
        </Card>
    </Tabs>
}






export default Results;