import MetricVisualizer from "../metricVisualizer"
import WeightMetricVisualizer from "../weightMetricVisualizer"
import ResultCard from "../ResultCard"

function NormalManComparison({factor,imperial}) {
    let weight = 90000/Math.pow(factor,3)
    let weightDescription = <span>Would weight around <WeightMetricVisualizer value={weight} imperial={imperial}/></span>
    let sizeDescription =  <span>Would look like they are about <MetricVisualizer value={165/factor} imperial={imperial}/></span>
    if(weight < 1) {
        weightDescription = <span>Would feel weightless</span>
    }

    if(165/factor < 0.1) {
        sizeDescription = <span>Would be a speck.</span>
    }
    return <ResultCard title={"A normal person"}>
        {sizeDescription} <br/>
        {weightDescription}
    </ResultCard>
}

export default NormalManComparison;