import styled from "styled-components"
import React, {useState} from 'react'
import {fulllistObjects} from "./sizeObjects";
import SizeComparisonObject from "./sizeComparisonObject";
import Button from "./button";
import { ToggleSwitch } from "./newtoggle";
import Switch from "react-switch";


export default function VisualComparison({factor,personWidth,cockLength,cockWidth,centimeters,footCM,name,softCockLength,softCockWidth}) {
    let personO = fulllistObjects(centimeters,factor,footCM,cockLength,cockWidth,personWidth,name,softCockLength,softCockWidth)

    const [imperial,setImperial] = useState(false);
    const [showOptions,setShowOptions] = useState(true);
    const [objects,setObjects] = useState([personO[0].value,personO[5].value])
    const [customObjects,setCustomObjects] = useState([false,false])
    const [showMeasurements,setShowMeasurements] = useState(true);
    const [categories,setCategories] = useState([])

    if(categories.length === 0) {
        let newCategories = []
        for(let i = 0;i < personO.length;i++){
            if(!newCategories.includes(personO[i].category)) {
                newCategories.push(personO[i].category);
            }
        }
        setCategories(newCategories);
    }
    let newObjects = [];
    for(let i = 0; i < objects.length;i++) {
        if(customObjects[i] === false) {
            for(let o = 0;o < personO.length;o++) {
                if(objects[i].key === personO[o].value.key && JSON.stringify(objects[i]) !== JSON.stringify(personO[o].value)){
                    newObjects.push(personO[o].value);
                } else if (objects[i].key === personO[o].value.key) {
                    newObjects.push(objects[i]);
                }
            }
        } else {
            newObjects.push(objects[i]);
        }
    }

    if (JSON.stringify(objects) !== JSON.stringify(newObjects)) {
        setObjects(newObjects);
    }

    let biggestObject = 0;

    for(let i = 0;i < objects.length;i++){
        if (objects[i].size > biggestObject) {
            biggestObject = objects[i].size;
        }
    }

    let multiplier = 25;

    function addObject() {
        let newObjects = [];
        let newCustoms = [];
        for(let i = 0; i < objects.length; i++) {
            newObjects.push(objects[i]);
            newCustoms.push(customObjects[i]);
        }
        newObjects.push(personO[0].value);
        newCustoms.push(false);
        setObjects(newObjects);
        setCustomObjects(newCustoms);
    }

    function removeObject(index) {
        let newObjects = [];
        let newCustoms = [];
        for(let i = 0; i < objects.length; i++) {
            if (i !== index) {
                newObjects.push(objects[i]);
                newCustoms.push(customObjects[i]);
            }
        }
        setObjects(newObjects);
        setCustomObjects(newCustoms);

    }

    function setObject(value,index) {
        let newObjects = [];
        let newCustoms = [];
        for(let i = 0;i < objects.length;i++){
            if(i !== index) {
                newObjects.push(objects[i]);
                newCustoms.push(customObjects[i]);
            } else {
                newObjects.push(value);
                newCustoms.push(false);
            }
        }
        setObjects(newObjects);
        setCustomObjects(newCustoms);
    }

    function setCustomPropeties(value,type,index){
        let newCustoms = [];
        let newObjects = [];
        for(let i = 0;i < objects.length;i++) {
            if(i !== index) {
                newObjects.push(objects[i]);
                newCustoms.push(customObjects[i]);
            } else {
                newObjects.push(value);
                newCustoms.push(type);
            }
        }
        setObjects(newObjects);
        setCustomObjects(newCustoms);
    }



    return <Parent id="parent">
        <label>
            <span>Show Options</span> <br/>
            <Switch onChange={() => setShowOptions(!showOptions)} checked={showOptions} />
        </label>
        {showOptions &&
        <TogglesSpacer>
            <label>
                <span>Imperial Units</span> <br/>
                <Switch onChange={() => setImperial(!imperial)} checked={imperial} />
            </label>
            <label>
                <span>Measurements</span> <br/>
                <Switch onChange={() => setShowMeasurements(!showMeasurements)} checked={showMeasurements} />
            </label>
            {(objects.length < 4) &&
                <div><Button onClick={addObject}>Add another object</Button></div>
            }
        </TogglesSpacer>
        }
        <h1> Visual Comparison</h1>
        <DualGrid >
            {objects.map((item, index) => <SizeComparisonObject removeObject={removeObject} categories={categories} showMeasurements={showMeasurements} setCustomPropeties={setCustomPropeties} object={item} setObject={setObject} scale={item.size / +biggestObject * +multiplier} objects={personO} key={index} index={index} imperial={imperial} biggestObject={biggestObject} multiplier={multiplier} showOptions={showOptions}/>)}
        </DualGrid >
    </Parent>
}

const Parent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const TogglesSpacer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #c6d8f1;
`

const DualGrid = styled.div`
    display: flex;
    width:100%;
    flex-direction: row;
    align-items: flex-end;
    align-content: bottom;
    justify-content: center;

    @media(max-width: 800px) {
        overflow-x: auto;
    }
    //grid-template-columns: 1fr 1fr; 
`

const Sel = styled.select`
    margin: 10px;
    height:100%;
    padding: 10px;
    font-size: 1.5rem;
    width: 16rem;
`
