import styled from "styled-components"

const Card = styled.div`
    background-color: #dae3f2;
    border-radius: 10px;
    text-align: center;
    padding: 20px;   
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: 20px;
`

export default Card