
import 'react-tabs/style/react-tabs.css';
import MetricMagnitudeSelector from '../magnitudeMetric';
import SimpleInput from '../SimpleInput';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export default function FutaInput({setFuta,cockLength,setCockLength,setCockWidth,cockWidth,balls,setBalls,cumProduction,setCumProduction,softCockLength,setSoftCockLength,softCockWidth,setSoftCockWidth}) {
    return <FutaMetricInput 
                setFuta={setFuta} 
                cockLength={cockLength} setCockLength={setCockLength}
                cockWidth={cockWidth} setCockWidth={setCockWidth}
                balls={balls} setBalls={setBalls}
                cumProduction={cumProduction} setCumProduction={setCumProduction}
                softCockLength={softCockLength} setSoftCockLength={setSoftCockLength}
                softCockWidth={softCockWidth} setSoftCockWidth={setSoftCockWidth}
        />
        
}

function FutaMetricInput({setFuta,cockLength,setCockLength,setCockWidth,cockWidth,balls,setBalls,cumProduction,setCumProduction,softCockLength,setSoftCockLength,softCockWidth,setSoftCockWidth}) {
    return <div>
        <Tabs>
            <TabList> 
                <Tab>Soft</Tab>
                <Tab>Hard</Tab>
            </TabList>
            <TabPanel> 
                Cock Length:
                <MetricMagnitudeSelector currentValue={softCockLength} set={setSoftCockLength} imperialOption/><br/>
                Cock Width: (diameter)
                <MetricMagnitudeSelector currentValue={softCockWidth} set={setSoftCockWidth} imperialOption/> <br/>
            </TabPanel>
            <TabPanel> 
                Cock Length:
                <MetricMagnitudeSelector currentValue={cockLength} set={setCockLength} imperialOption/><br/>
                Cock Width: (diameter)
                <MetricMagnitudeSelector currentValue={cockWidth} set={setCockWidth} imperialOption/> <br/>
            </TabPanel>
        
        </Tabs>

        Cum Production:
        <SimpleInput type="number" value={cumProduction} onChange={(e) => setCumProduction(e.target.value)}/> mL
    </div>   
}
