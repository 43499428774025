import styled from "styled-components"
import React from "react"

export default function SubtractAddInputField({value,add,remove,label}) {
    return <SubtractAddInput>
            <span><Button onClick={() => remove()}> - </Button> <Value>{value}</Value> <Button onClick={() => add()}> + </Button> {label} </span>
        </SubtractAddInput>
}

const SubtractAddInput = styled.div`
    background-color: white;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: stretch;
    margin: 10px;
    width: fit-content;
    padding-right: 8px;
    padding-left: 0px;
    gap:10px;
`

const Button = styled.button`
    background-color: #cccccc;
    margin: 10px;
    height:100%;
    padding: 10px;
    margin: auto;
    border: 0px;
    font-size: 1rem;
    font-weight: bolder;
`

const Value = styled.div`
    background-color:white;
    display:inline;
    font-weight: bolder;
    width: 40px;
    height: 30px;
    margin: auto;
`

