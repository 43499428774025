import React , { useState } from 'react';
import styled from 'styled-components';
import Switch from "react-switch";
import MetricMagnitudeSelector from "./components/magnitudeMetric";

function StandardInput({shoe,setShoe}) {

	const [shoeMode,setShoeMode] = useState(0)
	const [custom,setCustom] = useState(false)
	const sizeNumbers = [
		[5,5.5,6,6.5,7,7.5,8,8.5,9,9.5,10,10.5,11,11.5,12],
		[3,3.5,4,4.5,5,5.5,6,6.5,7,7.5,8,8.5,9,9.5,10],
		["35/36","36","36/37","37","37/38","38","38/39","39","39/40","40","40,41","41","41/42","42","42/43"]]
    return <div>
		
	Custom: <Switch onChange={() => setCustom(!custom)} checked={custom} />
	{custom ? <div> 
	Shoe size:
	<Sel name="shoeType" value={shoeMode} onChange={e => setShoeMode(e.target.value)}>
		<option value ={2}>EU</option>
		<option value ={1}>UK</option>
		<option value ={0}>US</option>
	</Sel>
	<Sel name="shoe" value={shoe} onChange={e => setShoe(e.target.value)}>
		<option value="21.6">{sizeNumbers[shoeMode][0]}</option>
		<option value="22.2">{sizeNumbers[shoeMode][1]}</option>
		<option value="22.5">{sizeNumbers[shoeMode][2]}</option>
		<option value="23">{sizeNumbers[shoeMode][3]}</option>
		<option value="23.5">{sizeNumbers[shoeMode][4]}</option>
		<option value="23.8">{sizeNumbers[shoeMode][5]}</option>
		<option value="24.1">{sizeNumbers[shoeMode][6]}</option>
		<option value="24.6">{sizeNumbers[shoeMode][7]}</option>
		<option value="25.1">{sizeNumbers[shoeMode][8]}</option>
		<option value="25.4">{sizeNumbers[shoeMode][9]}</option>
		<option value="25.9" defaultValue>{sizeNumbers[shoeMode][10]}</option>
		<option value="26.2">{sizeNumbers[shoeMode][11]}</option>
		<option value="26.7">{sizeNumbers[shoeMode][12]}</option>
		<option value="27.1">{sizeNumbers[shoeMode][13]}</option>
		<option value="27.6">{sizeNumbers[shoeMode][14]}</option>
	</Sel>
	</div>
	: 
	<div>
		Show Length:
		 <MetricMagnitudeSelector currentValue={shoe} set={setShoe} imperialOption/>
	</div>
	}
	</div>

}

export function BreastInputs ({cup,setCup}) {
	return <div>
	Breast size <b>**</b>:
	<Sel name="cup" value={cup} onChange={e => setCup(e.target.value)}>
		<option value="0.8">A&nbsp; &nbsp; &nbsp;</option>
		<option value="1.6">B</option>
		<option value="2.35" defaultValue>C</option>
		<option value="3.05">D</option>
		<option value="3.75">E</option>
		<option value="4.4">F</option>
		<option value="5.05">G</option>
		<option value="5.65">H</option>
		<option value="6.25">I</option>
		<option value="6.8">J</option>
	</Sel> cup<br/>
	</div>
}


const Sel = styled.select`
    margin: 10px;
    height:100%;
    padding: 10px;
    font-size: 1.5rem;
`
export default StandardInput;