import SpeedMetricVisualizer from "../speedMetricVisualizer";
import MetricVisualizer from "../metricVisualizer";
import ResultCard from "../ResultCard";
import { herHisUppercase } from "../../functions/pronouns";

function Movement({factor,factor170,imperial,gender}){
    let basicWalkStride = 76.2;
    let basicRunningStride = 152.4;
    let walkspeed = Math.floor((factor170 * basicWalkStride * factor * 6200));
    let walkstride = factor170 * basicWalkStride * factor;
    let runningstride = factor170 * basicRunningStride * factor;
    let runningspeed = Math.floor((runningstride * 8000));

    return <ResultCard title={"Movement"}>
        {herHisUppercase(gender)} walking stride is: <MetricVisualizer value={walkstride}  imperial={imperial}/><br/>
        {herHisUppercase(gender)} walking speed is: <SpeedMetricVisualizer value={walkspeed}  imperial={imperial}/> <br/>
        {herHisUppercase(gender)} running speed is: <SpeedMetricVisualizer value={runningspeed} imperial={imperial}/> <br/>
    </ResultCard>
}

export default Movement;