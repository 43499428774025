import { useState } from 'react';
import Card from './card'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';

function StorageManager({name,height,growthFactor,setHeight,setName,setGrowthFactor,weight,setWeight,lift,setLift,bust,setBust,hips,setHips,waist,setWaist,futa,setFuta,balls,setBalls,cockLength,setCockLength,cockWidth,setCockWidth,cumProduction,setCumProduction,cupSize,setCupSize,shoeSize,setShoeSize, musk,setMusk,softCockLength,setSoftCockLength,softCockWidth,setSoftCockWidth}) {
    
    const[characterList,setCharacterList] = useState([])
    const[selectedCharacter,setSelectedCharacter] = useState("");

    if(localStorage.getItem(`@EGC/CL`) !== null) {
        let clStorage = JSON.parse(localStorage.getItem(`@EGC/CL`))
        if (JSON.stringify(clStorage) !== JSON.stringify(characterList)) {
            setCharacterList(clStorage);
        }
    }
    

    if (characterList.length > 0 && selectedCharacter === "") {
        setSelectedCharacter(characterList[0])
    }
    

    function Save() {
        let saveFile = {
            name : name,
            weight : +weight,
            lift : +lift,
            bust : +bust,
            hips : +hips,
            waist : +waist,
            futa : futa,
            balls : +balls,
            cockLength : +cockLength,
            cockWidth : +cockWidth,
            cumProduction : +cumProduction,
            cupSize : +cupSize,
            shoeSize : +shoeSize,
            musk : +musk,
            height : +height,
            growthFactor: +growthFactor,
            softCockLength: +softCockLength,
            softCockWidth: softCockWidth,
            slot: characterList.length
        }
        AddToCharacterList(name)
        localStorage.setItem(`@EGC/${name}`,JSON.stringify(saveFile));
    }

    function AddToCharacterList(name) {
        let cl = characterList;
        if(!cl.includes(name)){
            cl.push(name);
            localStorage.setItem(`@EGC/CL`,JSON.stringify(cl));
            setCharacterList(cl);
        }
    }

    function Load() {
        let saveData = localStorage.getItem(`@EGC/${selectedCharacter}`)
        if (saveData !== null) {
            console.log(saveData);
            let saveFile = JSON.parse(saveData);
            console.log(saveFile);
            setHeight(+saveFile.height);
            setGrowthFactor(+saveFile.growthFactor);
            setName(saveFile.name);
            setWeight(saveFile.weight);
            setLift(saveFile.lift);
            setBust(saveFile.bust);
            setHips(saveFile.hips);
            setWaist(saveFile.waist);
            setFuta(saveFile.futa);
            setBalls(saveFile.balls);
            setCockLength(saveFile.cockLength);
            setCockWidth(saveFile.cockWidth);
            setCumProduction(saveFile.cumProduction);
            setCupSize(saveFile.cupSize);
            setShoeSize(saveFile.shoeSize);
            setMusk(saveFile.musk);
            setSoftCockLength(saveFile.softCockLength);
            setSoftCockWidth(saveFile.softCockWidth);
        }
        
    }

    return <Card>
        <Tabs>
            <TabList>
                <Tab>Save</Tab>
                <Tab>Load</Tab>
            </TabList>
            <TabPanel>
                <b>Save</b> <br/>
                <b>Name:</b>{name} <br/>
                <b>Height:</b>{height} <br/>
                <b>Growth:</b>{growthFactor} <br/>
                <button onClick={() => Save()}>Save character</button>
            </TabPanel>
            <TabPanel>
                Load <br/>
                <Sel value={selectedCharacter} onChange={(e) => setSelectedCharacter(e.target.value)}>
                    {characterList.map((item) => <option value={item} key={item}>{item}</option>)}
                </Sel>
                <LoadPreview characterName={selectedCharacter}/>
                <button onClick={() => Load()}>Load</button>
            </TabPanel>
        </Tabs>
        
    </Card>
}

function LoadPreview({characterName}) {
    let saveData = localStorage.getItem(`@EGC/${characterName}`)
    let saveFile = JSON.parse(saveData);
    if(saveData !== null) {
    return <div>
        Height: {saveFile.height} cm <br/>
        Growth Factor: {saveFile.growthFactor}x <br/>
        Grown Height: {saveFile.height * saveFile.growthFactor} cm<br/>
    </div>
    } else {
        return <div>
            No character found
        </div>
    }
    
}

const Sel = styled.select`
    margin: 10px;
    height:100%;
    padding: 10px;
    font-size: 1.5rem;
    width: 16rem;
`

export default StorageManager;


