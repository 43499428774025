export function sheHeUppercase(gender) {
    if (gender === 0) {
        return "He"
    } else {
        return "She"
    }
}

export function sheHeLowercase(gender) {
    if (gender === 0) {
        return "he"
    } else {
        return "she"
    }
}

export function herHisLowercase(gender) {
    if (gender === 0) {
        return "his"
    } else {
        return "her"
    }
}

export function herHisUppercase(gender) {
    if (gender === 0) {
        return "His"
    } else {
        return "Her"
    }
}