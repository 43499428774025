
import MetricVisualizer from "../metricVisualizer"
import ResultCard from "../ResultCard"
import { herHisUppercase } from "../../functions/pronouns"

export default function MuskResults({musk , factor, imperial,gender}) {
    let afterMusk = musk * Math.pow(factor,2)
    return <ResultCard title="Scent"> 
    {herHisUppercase(gender)} scent can be felt up to <MetricVisualizer value={afterMusk} imperial={imperial}/>
    </ResultCard>
}