import { FullFeetToFormattedFeetAndInches } from '../functions/converters';
import bigInt from 'big-integer';

function MetricVisualizer({value,imperial}) {
    let magnitude = 1;
    let title = "centimeters"
    if (value >= 300000) {
        title = "kilometers"
        magnitude = 100000;
    } else if (value > 200) {
        title = "meters"
        magnitude = 100;
    }

    if (imperial !== null && imperial !== false) {
        magnitude = 2.54;
        title = "inches"
        if (+value >= 321868) {
            title = "miles"
            magnitude = 160934;
        }else if (+value >= 80467) {
            title = "mile"
            magnitude = 160934;
        } else if (+value > 30.4) {
            title = "feet"
            magnitude = 30.4;
        }
    }
    if (value > 10000000) {
        value = bigInt(Math.floor(value))
        if (value >= bigInt(946073047258004200)) {
            title = "light years"
            magnitude = 946073047258004200;
        }
    }
    return <span>
        { !( title === "feet") 
        ? <span> {new Intl.NumberFormat().format((value / magnitude))} {title} </span>
        : <span> {FullFeetToFormattedFeetAndInches(value / magnitude)}</span>
        }
    </span>
}


export default MetricVisualizer;