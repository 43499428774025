import React from 'react';
import Card from './components/card';
import StandardInput, { BreastInputs } from './standardinputs';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import FutaInput from './components/inputs/FutaInputs';
import { FeetAndInchesInput } from './components/ImperialLengthInput';
import SmallSimpleInput from './components/SmallSimpleInput';
import MuskInputs from './components/inputs/MuskInputs';
import PersonalInput from './components/inputs/personalInput';
import { sheHeLowercase , sheHeUppercase , herHisUppercase } from './functions/pronouns';

function BeforeInputs({preCentimeters,setPreCentimeters,weight,setWeight,lift,setLift,bust,setBust,hips,setHips,waist,setWaist,futa,setFuta,balls,setBalls,cockLength,setCockLength,cockWidth,setCockWidth,cumProduction,setCumProduction,cupSize,setCupSize,shoeSize,setShoeSize, musk,setMusk,name,setName,softCockLength,setSoftCockLength,setSoftCockWidth,softCockWidth,gender,setGender}) {
    return <div>
        <Card>
            <b>Before {sheHeLowercase(gender)} grows:</b><br/>
            <Tabs>
            <TabList>
                <Tab>Metric</Tab>
                <Tab>Imperial</Tab>
            </TabList>

            <TabPanel>
                <MetricInput 
                    preCentimeters={preCentimeters} setPreCentimeters={setPreCentimeters}
                    weight={weight} setWeight={setWeight}
                    lift={lift} setLift={setLift}
                    bust={bust} setBust={setBust}
                    waist={waist} setWaist={setWaist}
                    hips={hips} setHips={setHips}
                    futa={futa} setFuta={setFuta}
                    balls={balls} setBalls={setBalls}
                    cockLength={cockLength} setCockLength={setCockLength}
                    cockWidth={cockWidth} setCockWidth={setCockWidth}
                    cumProduction={cumProduction} setCumProduction={setCumProduction}
                    gender={gender}
                />
            </TabPanel>
            <TabPanel>
                <ImperialInput
                    preCentimeters={preCentimeters} setPreCentimeters={setPreCentimeters}
                    weight={weight} setWeight={setWeight}
                    lift={lift} setLift={setLift}
                    bust={bust} setBust={setBust}
                    waist={waist} setWaist={setWaist}
                    hips={hips} setHips={setHips}
                    futa={futa} setFuta={setFuta}
                    balls={balls} setBalls={setBalls}
                    cockLength={cockLength} setCockLength={setCockLength}
                    cockWidth={cockWidth} setCockWidth={setCockWidth}
                    cumProduction={cumProduction} setCumProduction={setCumProduction}
                    gender={gender}
                />
            </TabPanel>
        </Tabs>
        </Card>
        <Card> 
        <Tabs>
            <b>Extra Data:</b>
            <TabList>
                <Tab>Personal</Tab>
                <Tab>Feet</Tab>
                { (gender === 2 || gender === 1) &&
                    <Tab>Breasts</Tab>
                }
                { (gender === 2 || gender === 0) &&
                    <Tab>Cock</Tab>
                }
                <Tab>Musk</Tab>
            </TabList>

            <TabPanel>
                <PersonalInput name={name} setName={setName} gender={gender} setGender={setGender}/>
            </TabPanel>
            <TabPanel>
                <StandardInput shoe={shoeSize} setShoe={setShoeSize}/>
            </TabPanel>
            { (gender === 2 || gender === 1) &&
            <TabPanel>
                <BreastInputs cup={cupSize} setCup={setCupSize}/>
            </TabPanel>
            }
            { (gender === 2 || gender === 0) &&
            <TabPanel>
                <FutaInput 
                    setFuta={setFuta} 
                    cockLength={cockLength} setCockLength={setCockLength}
                    cockWidth={cockWidth} setCockWidth={setCockWidth}
                    balls={balls} setBalls={setBalls}
                    cumProduction={cumProduction} setCumProduction={setCumProduction}
                    softCockLength={softCockLength} setSoftCockLength={setSoftCockLength}
                    softCockWidth={softCockWidth} setSoftCockWidth={setSoftCockWidth}
                />
            </TabPanel>
            }
            <TabPanel>
                <MuskInputs musk={musk} setMusk={setMusk} gender={gender}/>
            </TabPanel>
        </Tabs>
        </Card>
    </div>
}

function MetricInput({preCentimeters,setPreCentimeters,weight,setWeight,lift,setLift,bust,setBust,hips,setHips,waist,setWaist,futa,setFuta,cockLength,setCockLength,cockWidth,setCockWidth,balls,setBalls,cumProduction,setCumProduction,gender}) {
    
    return <div>
        {herHisUppercase(gender)} height:
        <SmallSimpleInput name="wmncentimeters"  size="1" value={preCentimeters} type="number" onChange={e => setPreCentimeters(e.target.value)}/> centimeters. <br/>

        {herHisUppercase(gender)} weight:
        <SmallSimpleInput name="wmnweightkilos"  size="1" value={weight} type="number" onChange={e => setWeight(e.target.value)}/> kilos <br/>


        {sheHeUppercase(gender)} can lift:
        <SmallSimpleInput name="gtsliftkilos"  size="1" value={lift} type="number" onChange={e => setLift(e.target.value)}/> kilos<br/>


        Measurements (cm):<br/>
        <span>Bust <SmallSimpleInput name="key1"  size="1" value={bust} type="number" onChange={e => setBust(e.target.value)}/> </span> 
        <span>Waist <SmallSimpleInput name="key2"  size="1" value={waist} type="number" onChange={e => setWaist(parseInt(e.target.value))}/></span> 
        <span>Hips <SmallSimpleInput name="key3" size="1" value={hips} type="number" onChange={e => setHips(e.target.value)}/></span>

        
    </div>
}

function ImperialInput({preCentimeters,setPreCentimeters,weight,setWeight,lift,setLift,bust,setBust,hips,setHips,waist,setWaist,gender}) {

    function KiloToPound(value) {
        return Math.trunc(value * 2.205)
    }

    function PoundToKiloSet(value) {
        if (value * 0.453592 <= weight) {
            setWeight(Math.trunc(value * 0.453592))
        } else {
            setWeight(Math.ceil(value * 0.453592))
        } 
    }

    function PoundToKiloLiftSet(value) {
        if (value * 0.453592 <= lift) {
            setLift(Math.trunc(value * 0.453592));
        } else {
            setLift(Math.ceil(value * 0.453592));
        } 
    }

    function InchToCmSet(value,originalValue,set) {
        let cmValue = value * 2.54;
        if (cmValue < 1) {
            cmValue = 1;
        }
        if (cmValue <= originalValue) {
            set(Math.trunc(cmValue))
        } else {
            set(Math.ceil(cmValue))
        } 
    }

    return <div>
        {herHisUppercase(gender)} height:
        <FeetAndInchesInput value={preCentimeters} setValue={setPreCentimeters} /> <br/>
        {herHisUppercase(gender)} weight:
        <SmallSimpleInput  value={KiloToPound(weight)} type="number" onChange={e => PoundToKiloSet(e.target.value)}/> pounds <br/>


        {sheHeUppercase(gender)} can lift:
        <SmallSimpleInput  value={KiloToPound(lift)} type="number" onChange={e => PoundToKiloLiftSet(e.target.value)}/> pounds.<br/>


        Measurements (inches):<br/>
        <span>Bust <SmallSimpleInput name="key1"   value={Math.trunc(bust / 2.54)} type="number" onChange={e => InchToCmSet(e.target.value,bust,setBust)}/></span> 
        <span>Waist <SmallSimpleInput name="key2"  value={Math.trunc(waist / 2.54)} type="number" onChange={e => InchToCmSet(e.target.value,waist,setWaist)}/></span> 
        <span>Hips <SmallSimpleInput name="key3"  value={Math.trunc(hips / 2.54)} type="number" onChange={e => InchToCmSet(e.target.value,hips,setHips)}/></span>
    </div>
}




export default BeforeInputs;