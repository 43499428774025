import ResultCard from "../ResultCard"
import { herHisUppercase } from "../../functions/pronouns"

function Forces({factor,factor120,beforeWeight,weight,gender}) {
    // 9.81 gravidade da terra, traduzindo a velocidade que a personagem bota pra baixo a perna.
    // 
    let stepForce = weight/2*(9.81)*(0.07*factor)
    let stompForce = weight/2*(11.5)*(0.5*factor)
    let jumpForce = weight*(9.81)*(0.43*factor)

    return <ResultCard title={"Forces"}>
        {herHisUppercase(gender)} step releases {stepForce.toFixed(0)} joules. <br/>
        {herHisUppercase(gender)} stomp releases {stompForce.toFixed(0)} joules. <br/>
        {herHisUppercase(gender)} jump releases {jumpForce.toFixed(0)} joules. <br/>
    </ResultCard>
}

export default Forces;