export function MilesToInches(value){
    return FeetToInches(MilesToFeet(value));
}

export function MilesToCm(value){
    return InchesToCm(FeetToInches(MilesToFeet(value)))
}

export function FeetToCm(value){
    return InchesToCm(FeetToInches(value));
}

export function FeetToInches(value){
    return value * 12;
}

export function InchesToCm(value){
    return value * 2.54;
}

export function MilesToFeet(value){
    return value * 5280;
}

export function FullFeetToFormattedFeetAndInches(value) {
    let totalInches = value * 12;
    let remainingInches = totalInches % 12;
    let feet = Math.floor(totalInches / 12)
    
    if (remainingInches > 0 && feet > 0) {
        return ` ${feet}'${remainingInches.toFixed(0)}"`
    } else if (remainingInches === 0 && feet > 0) {
        return ` ${feet.toFixed(0)}'`
    } else if (remainingInches > 0 && feet === 0) {
        return `${remainingInches.toFixed(0)}"`
    }
}
