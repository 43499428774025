import MetricVisualizer from "../metricVisualizer"
import ResultCard from "../ResultCard";
import { herHisLowercase } from "../../functions/pronouns";

function Keypoints({factor,headHeight,imperial,gender}) {
    let ankleHeight = headHeight/3;
    let kneeHeight = headHeight*1.875;
    let crotchHeight = headHeight*3.5;
    let hipHeight = headHeight*4;
    let neckHeight = headHeight*5.75;
    let chinHeight = headHeight*6;
    return <ResultCard title={"Keypoints"}>
        <MetricVisualizer value={ankleHeight}  imperial={imperial}/> to {herHisLowercase(gender)} ankles. <br/>
        <MetricVisualizer value={kneeHeight} imperial={imperial}/> to {herHisLowercase(gender)} knees. <br/>
        <MetricVisualizer value={crotchHeight} imperial={imperial}/> to {herHisLowercase(gender)} crotch. <br/>
        <MetricVisualizer value={hipHeight} imperial={imperial}/> to {herHisLowercase(gender)} hips. <br/>
        <MetricVisualizer value={neckHeight} imperial={imperial}/> to {herHisLowercase(gender)} neck. <br/>
        <MetricVisualizer value={chinHeight} imperial={imperial}/> to {herHisLowercase(gender)} chin. <br/>
    </ResultCard>
}

export default Keypoints;