import styled from "styled-components";
import SubtractAddInputField from "./SubtractAddInputField";
import React , { useState } from "react";
import empty from "../empty.png"
import bottle from "../bottle.png"
import content from "../contents.png"
import SmallSimpleInput from "./SmallSimpleInput";
export default function LiquidVisualizer({}) {
    const [amount,setAmount] = useState(800);
    
    let translation = ((69 / 100) * ( 100 - amount)) + 28
    let ContentBottles = []
    let value = amount;
    let leftover = value % 500
    let bottles = Math.floor(value / 500);
    
    console.log(bottles);
    
    function translateMl(value) {
        return ((69 / 100) * ( 100 - (value / 500) * 100)) + 28
    }


    for(let i = 0; i < bottles;i++) {
        ContentBottles.push(<BottleDisplay amount={translateMl(500)}/>);
    }

    if(leftover > 0) {
        ContentBottles.push(<BottleDisplay amount={translateMl(leftover)}/>)
    }



    

    return <div>
        <h3> This is still a WIP feature. </h3>
        <SmallSimpleInput type="number" value={amount} onChange={(e) => setAmount(e.target.value)}/> mL
        <Grid>
            {ContentBottles}
        </Grid>
        
        
        
        
    </div>

}

function BottleDisplay({amount}) {

        return <div> <Container>
            <Bottle src={bottle}/>
            <Bottle2 src={empty}/>
            <Bottle2 src={content} percentage={amount}/>
        </Container> </div>
}

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`


const Container = styled.div`
    display:flex;
    position:relative;
    width: 95px;
    height: 200px;
`

const Bottle = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    
`

const Bottle2 = styled.img`
    position: absolute; 
    top: 0; 
    left: 0;
    clip-path: inset(${props => props.percentage}% 0px 0px 0px);
    height: inherit;
`


const Background1 = styled.div`
    background-color: red;
    height: 400px;
    width: 400px;
    display:flex;
    align-items: flex-end;
`

const Liquid = styled.div`
    height: ${props => props.percentage}%;
    width: 100%;
    background-color: white;
`

