
import styled from "styled-components";

export default function ResultCard ({title,children} ) {
    return <RCard>
        <h1> {title} </h1>
        {children}
    </RCard>
}

const RCard = styled.div`

`