import React, { useState } from 'react';
import GiantessInputComponent from './giantessinput.js'
import Results from './newResults';
import BeforeInputs from './BeforeGrowthInputs';
import styled from 'styled-components';
import MainGrid from './components/mainGrid.js';
import StorageManager from './components/StorageManager.js';

function App() {

    const [gender, setGender] = useState(2);

    const [preCentimeters,setPreCentimeters] = useState(150);
    const [afterCentimeters,setAfterCentimeters] = useState(150);
    const [growthFactor,setGrowthFactor] = useState(1);

    const [weight,setWeight] = useState(80);
    const [lift,setLift] = useState(60);

    const [bust,setBust] = useState(90);
    const [hips,setHips] = useState(90);
    const [waist,setWaist] = useState(60);

    const [futa,setFuta] = useState(false)
    const [cockLength,setCockLength] = useState(13)
    const [cockWidth,setCockWidth] = useState(4)
    const [softCockLength,setSoftCockLength] = useState(13)
    const [softCockWidth,setSoftCockWidth] = useState(4)
    const [balls,setBalls] = useState(15)
    const [cumProduction,setCumProduction] = useState(5)

    const [cupSize,setCupSize] = useState(2.35);
    const [shoeSize,setShoeSize] = useState(25.9);

    const [muskRange,setMuskRange] = useState(300);

    const [name,setName] = useState("Jeanne")

    return <Main>
        <PageTitle>Easy Giantess Calculator</PageTitle>
        <MainGrid>
            <div>
                <BeforeInputs 
                    preCentimeters={preCentimeters} setPreCentimeters={setPreCentimeters} 
                    weight={weight} setWeight={setWeight} 
                    lift={lift} setLift={setLift}
                    bust={bust} setBust={setBust}
                    hips={hips} setHips={setHips}
                    waist={waist} setWaist={setWaist}
                    futa={futa} setFuta={setFuta}
                    cockLength={cockLength} setCockLength={setCockLength}
                    cockWidth={cockWidth} setCockWidth={setCockWidth}
                    softCockLength={softCockLength} setSoftCockLength={setSoftCockLength}
                    softCockWidth={softCockWidth} setSoftCockWidth={setSoftCockWidth}
                    balls={balls} setBalls={setBalls}
                    cumProduction={cumProduction} setCumProduction={setCumProduction}
                    cupSize={cupSize} setCupSize={setCupSize}
                    shoeSize={shoeSize} setShoeSize={setShoeSize}
                    musk={muskRange} setMusk={setMuskRange}
                    name={name} setName={setName}
                    gender={gender} setGender={setGender}
                />
                <GiantessInputComponent afterCentimeters={afterCentimeters} setAfterCentimeters={setAfterCentimeters} preCentimeters={preCentimeters} growthFactor={growthFactor} setGrowthFactor={setGrowthFactor} gender={gender} />
                <StorageManager 
                    height={preCentimeters} setHeight={setPreCentimeters}
                    name={name} setName={setName}
                    growthFactor={growthFactor} setGrowthFactor={setGrowthFactor}  
                    weight={weight} setWeight={setWeight} 
                    lift={lift} setLift={setLift}
                    bust={bust} setBust={setBust}
                    hips={hips} setHips={setHips}
                    waist={waist} setWaist={setWaist}
                    futa={futa} setFuta={setFuta}
                    cockLength={cockLength} setCockLength={setCockLength}
                    cockWidth={cockWidth} setCockWidth={setCockWidth}
                    softCockLength={softCockLength} setSoftCockLength={setSoftCockLength}
                    softCockWidth={softCockWidth} setSoftCockWidth={setSoftCockWidth}
                    balls={balls} setBalls={setBalls}
                    cumProduction={cumProduction} setCumProduction={setCumProduction}
                    cupSize={cupSize} setCupSize={setCupSize}
                    shoeSize={shoeSize} setShoeSize={setShoeSize}
                    musk={muskRange} setMusk={setMuskRange}
                />
            </div>
            <div name={"Test"}>
            <Results 
                beforeCentimeters={preCentimeters}
                factor={growthFactor}
                beforeHips={hips}
                beforeWeight={weight}
                beforeLift={lift}
                futa={futa}
                beforeCockLength={cockLength}
                beforeCockWidth={cockWidth}
                beforeCumProduction={cumProduction}
                beforeFeetSize={shoeSize}
                beforeMuskRange={muskRange}
                name={name}
                softCockLength={softCockLength}
                softCockWidth={softCockWidth}
                gender={gender}
            />
            </div>
        </MainGrid>
        {/* <Footer>
            <p>Author: CEOJeanne / Jahi the Jackal</p>
            <p>Discord: Jahi#0001 </p>
            <p >If you enjoy using the calculator, consider <a href="https://ko-fi.com/U7U1P8UM"> donating to it's development! </a> </p>
        </Footer> */}
    </Main>
}

const PageTitle = styled.h1`
    text-align: center;
`



const Main = styled.div`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0px;
`

export default App;