import styled from "styled-components";

const MainGrid = styled.div`
    display: grid;
    grid-template-columns: minmax(400px,2fr) minmax(400px,3fr);
    gap: 10px;

    @media(max-width: 800px) {
      grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
      gap: 10px;
  }
`
export default MainGrid;