
import MetricVisualizer from "../metricVisualizer"
import WeightMetricVisualizer from "../weightMetricVisualizer"
import ResultCard from "../ResultCard"
import { herHisLowercase , herHisUppercase , sheHeUppercase } from "../../functions/pronouns"

function SimpleMetrics ({factor, afterCentimeters,headWidth,shoulderWidth,hips,weight,lift,imperial,gender}) {
    return <ResultCard title={"Simple Metrics"}>
        {sheHeUppercase(gender)} is now {factor.toFixed(0)} times bigger <br/>
        {sheHeUppercase(gender)} is now <MetricVisualizer value={afterCentimeters} imperial={imperial}/> tall. <br/>
        {herHisUppercase(gender)} head is <MetricVisualizer value={headWidth} imperial={imperial}/> wide. <br/>
        {herHisUppercase(gender)} shoulders are <MetricVisualizer value={shoulderWidth} imperial={imperial}/> wide. <br/>
        {herHisUppercase(gender)} hips are <MetricVisualizer value={hips} imperial={imperial}/> wide. <br/>
        {sheHeUppercase(gender)} weights <WeightMetricVisualizer value={weight * 1000} imperial={imperial}/> <br/>
        {sheHeUppercase(gender)} can lift <WeightMetricVisualizer value={lift * 1000} imperial={imperial}/>
    </ResultCard>
}

export default SimpleMetrics