import React from 'react';
import formattedNumber from '../functions/formattedNumber';

function WeightMetricVisualizer({value}) {

    let magnitude = 1;
    let title = "grams"
    if (value >= 1000000) {
        title = "tons"
        magnitude = 1000000;
    } else if (value > 1500) {
        title = "kilogram"
        magnitude = 1000;
    }
    
    return <span>
        {formattedNumber((value / magnitude),1)} {title}
    </span>
}



export default WeightMetricVisualizer;