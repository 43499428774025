import React from 'react';
import formattedNumber from '../functions/formattedNumber';

function SpeedMetricVisualizer({value}) {

    let magnitude = 1;
    let title = "centimeters per hour"
    if (value >= 200000) {
        title = "Kilometers per hour"
        magnitude = 100000;
    } else if (value > 200) {
        title = "Miles per hour"
        magnitude = 100;
    }
    return <span>
        {formattedNumber((value / magnitude),1)} {title}
    </span>
}



export default SpeedMetricVisualizer;