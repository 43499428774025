import React ,{useState} from 'react';
import MetricMagnitudeSelector from './components/magnitudeMetric';
import Card from './components/card';
import SmallSimpleInput from './components/SmallSimpleInput';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Toggle from 'react-styled-toggle';
import { sheHeUppercase , sheHeLowercase } from './functions/pronouns';

function GiantessInputComponent({preCentimeters,growthFactor,setGrowthFactor,gender}) {
    const [enableGrowth,setEnableGrowth] = useState(true);

    if(!enableGrowth) {
        setGrowthFactor(1);
    }


    return <Card>
        <b>How much {sheHeLowercase(gender)} will grow:</b>
    <br/>
        <span> <Toggle value={enableGrowth} onChange={() => setEnableGrowth(!enableGrowth)}/> Grow</span>
        {enableGrowth &&
        <Tabs>
            <TabList>
                <Tab>Measurement</Tab>
                <Tab>Growth Factor</Tab>
            </TabList>
            <TabPanel>
                <MetricInput growthFactor={growthFactor} setGrowthFactor={setGrowthFactor} preCentimeters={preCentimeters} gender={gender}/>
            </TabPanel>
            <TabPanel>
                <FactorInput growthFactor={growthFactor} setGrowthFactor={setGrowthFactor} gender={gender}/>
            </TabPanel>
        </Tabs>
        }
    </Card>

}

function MetricInput({preCentimeters,growthFactor, setGrowthFactor,gender}) {

    let description = "Her height as a giantess:"

    if(gender === 0) {
        description = "His height as a giant:"
    }

    function handleResult(value) {
        setGrowthFactor(CalculateNewFactor(preCentimeters,value));
    }

    function CalculateNewFactor(preCentimeters,afterCentimeters){
        return (afterCentimeters / preCentimeters);
    }
    return <div>
        {description}
        <MetricMagnitudeSelector currentValue={(preCentimeters * growthFactor).toFixed(1)} set={handleResult} imperialOption/>
    </div>
}

function FactorInput({growthFactor, setGrowthFactor,gender}) {
    
    function Handle(value) {
        if(value > 0.01) {
            setGrowthFactor(value);
        }
    }

    return <div>
        {sheHeUppercase(gender)} grew:
        <SmallSimpleInput type="number" value={growthFactor} onChange={(e) => Handle(+e.target.value)} /> times
    </div>
}







export default GiantessInputComponent;