import styled from "styled-components";

const Button = styled.button`
    padding: 8px;
    margin: 8px;
    border-radius: 16px;
    border-width: 4px;
    border-color: #EDD7D7;
    border-style: none;
    font-size: 1rem;
    font-weight: bold;
`

export default Button;