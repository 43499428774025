import SimpleInput from "../SimpleInput"
import CustomDropdown from "../CustomDropdown"

export default function PersonalInput ({name,setName,gender,setGender}) {
    let GenderOptions = [{label: "Male",value: 0},{label: "Female",value: 1},{label: "Futa",value: 2}]
    return <div>
        Name: <SimpleInput value={name} onChange={ (e) => setName(e.target.value)} /> <br/>

        Gender: <CustomDropdown options={GenderOptions} value={gender} setValue={setGender} defaultOption={"Futa"}/> 
    </div>
}