import React, { useState } from 'react';
import styled from 'styled-components';
import SmallSimpleInput from './SmallSimpleInput';

function MetricMagnitudeSelector({currentValue,set,imperialOption}) {
    
    const [magnitude,setMagnitude] = useState(1)

    function setWithMagnitude(value) {
        let trueValue = value * magnitude
        if (trueValue < 1) {
            trueValue = 1;
        }
        set(trueValue)
    }

    function changeMagnitude(newMagnitude) {
        console.log(newMagnitude)
        setMagnitude(newMagnitude);
    }

    return <div>
        <SmallSimpleInput type="number" value={(currentValue / magnitude).toFixed(1)} onChange={e => setWithMagnitude(e.target.value)}/>
        {imperialOption 
        ? <Sel value={magnitude} onChange={(e) => changeMagnitude(e.target.value)} id="cars">
            <option value={1}>Centimeters</option>
            <option value={100}>Meters</option>
            <option value={100000}>Kilometers</option>
            <option value={2.54}>Inches</option>
            <option value={30.48}>Feet</option>
        </Sel>
        : <Sel value={magnitude} onChange={(e) =>  changeMagnitude(e.target.value)} id="cars">
        <option value={1}>Centimeters</option>
        <option value={100}>Meters</option>
        <option value={100000}>Kilometers</option>
        </Sel>
        }
    </div>
}

const Sel = styled.select`
    margin: 10px;
    height:100%;
    padding: 10px;
    font-size: 1.5rem;
    width: 12rem;
`



export default MetricMagnitudeSelector;