import Card from "../card"
import MetricVisualizer from "../metricVisualizer"
import { herHisUppercase } from "../../functions/pronouns"
function FeetStats ({factor, beforeFeetSize,imperial,gender}) {

    let feetSize = factor * beforeFeetSize
    return <Card>
        <h1>Feet Stats</h1>
        {herHisUppercase(gender)} feet is <MetricVisualizer imperial={imperial} value={feetSize}/> long <br/>
        {herHisUppercase(gender)} feet is <MetricVisualizer imperial={imperial} value={feetSize / 2.365}/> wide <br/>
        {herHisUppercase(gender)} big toe is <MetricVisualizer imperial={imperial} value={feetSize / 5.05}/> long
    </Card>
}

export default FeetStats