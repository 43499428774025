import WeightMetricVisualizer from "../weightMetricVisualizer";
import MetricVisualizer from "../metricVisualizer";
import LiquidMetricVisualizer from "../liquidMetricVisualizer";
import ResultCard from "../ResultCard";
import { herHisUppercase , sheHeUppercase } from "../../functions/pronouns";

function FutaStats({factor,beforeCockLength,beforeCockWidth,beforeCumProduction,imperial,gender}) {

    let cockLength = beforeCockLength * factor;
    let cockWidth = beforeCockWidth * factor;
    let cockradius = cockWidth / 2;
    let cockvolume =  3.141592 * Math.pow(cockradius,2) * cockLength;
    let cockweight = cockvolume * 1.06;
    let CumProduction = beforeCumProduction * Math.pow(factor,3);
    return <ResultCard title={"Cock"}>
        {herHisUppercase(gender)} cock is <MetricVisualizer value={cockLength} imperial={imperial}/> long. <br/>
        {herHisUppercase(gender)} cock is <MetricVisualizer value={cockWidth} imperial={imperial}/> thick. <br/>
        It's volume is  <LiquidMetricVisualizer value={(cockvolume)} imperial={imperial}/> <br/>
        It weights <WeightMetricVisualizer value={cockweight.toFixed(3)} imperial={imperial}/> <br/>
        {sheHeUppercase(gender)} produces <LiquidMetricVisualizer value={CumProduction} imperial={imperial}/> per ejaculation!
    </ResultCard>
}

export default FutaStats;