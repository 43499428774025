import styled from "styled-components"

export function ToggleSwitch({value,set,label}) {
    return <Switch>
        <Checkbox type={"checkbox"} checked={value} onChange={(e) => set(!value)}/>
        <Slider/>
        <Wrapper toggle={value}> Foo </Wrapper>;
    </Switch>
}

const Wrapper = styled.div`
  display: block;
  background: grey;
  ${props => {
    if (props.toggle) {
      return `
        position: absolute;
        left: 1px;
        right: auto;
        top: 2px;
        background: blue;
      `;
    } else {
      return `
        position: absolute;
        left: auto;
        top: 2px;
        right: 30px;
        background: yellow;
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      `;
    }
  }}
`;

const Checkbox = styled.input`
    &:checked {
        background-color: #2196F3;
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);

    }
    &:focus {
        background-color: blue;
    }

    &{Slider}:focus  {
        background-color: red;
    }   

    &:checked + {Slider}:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    
`

const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: orange;
        -webkit-transition: .4s;
        transition: .4s;
    }
`

const Switch = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
`