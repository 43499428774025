import SmallSimpleInput from "./SmallSimpleInput";
import { MilesToInches , FeetToInches } from "../functions/converters";
import SubtractAddInputField from "./SubtractAddInputField";


export function SimpleInchesInput({centimeters, setCentimeters}) {
    let totalInches = centimeters / 2.54
    return <span>
        <SmallSimpleInput type="number" value={totalInches.toFixed(0)} onChange={(e) => InchToCmSet(e.target.value,centimeters,setCentimeters)}/> inches
    </span>
}

export default function ImperialInput({centimeters, setCentimeters,magnitude}) {
    let totalInches = centimeters / 2.54;
    let remainingInches = Math.trunc(totalInches % 12);
    let feet = Math.trunc(totalInches / 12);
    let remainingFeet = Math.trunc(feet % 5280);
    let miles = Math.floor(feet/ 5280);

    function Set(rmiles,rfeet,inches){
        console.log(inches);
        if (+inches >= 11) {
            inches = 11;
        }
        let totalInches = +inches + +FeetToInches(rfeet) + +MilesToInches(rmiles);
        console.log(totalInches);
        InchToCmSet(totalInches,centimeters,setCentimeters);
    }

    return <span>
        {magnitude === 2 && 
        <SmallSimpleInput type="number" value={miles} onChange={(e) => Set(e.target.value,remainingFeet,remainingInches)}/> }
        <SmallSimpleInput type="number" value={remainingFeet} onChange={(e) => Set(miles,e.target.value,remainingInches)}/>
        feet <SmallSimpleInput type="number" value={remainingInches} onChange={(e) => Set(miles,remainingFeet,e.target.value)}/> inches
    </span>
}

export function FeetAndInchesInput({value, setValue}) {
    let totalInches = value / 2.54
    let remainingInches = Math.trunc(totalInches % 12)
    let feet = Math.trunc(totalInches / 12)

    function Set(feet,inches){
        if(inches >= 11) {
            inches = 11;
        }
        let total = (+feet * 12) + +inches;
        let totalCM = InchToCm(total);
        setValue(totalCM);
    }

    function addInch(){
        if (remainingInches === 11) {
            Set(feet + 1,0)
        } else {
            Set(feet,remainingInches + 1)
        }
    }

    function removeInch(){
        if (remainingInches === 0) {
            Set(feet - 1,11)
        } else {
            Set(feet,remainingInches - 1)
        }
    }

    function addFeet(){
        Set(feet + 1,remainingInches)
    }

    function removeFeet(){
        if (feet > 1) {
            Set(feet - 1,remainingInches)
        } 
    }

    return <span>
        <SubtractAddInputField add={addFeet} value={feet} remove={removeFeet} label={"feet"}/> and 
        <SubtractAddInputField add={addInch} value={remainingInches} remove={removeInch} label={"inches"}/>
    </span>
}





function InchToCmSet(value,originalValue,set) {
    let cmValue = value * 2.54;
    if (cmValue <= originalValue) {
        set(Math.trunc(cmValue))
    } else {
        set(Math.ceil(cmValue))
    } 
}

function InchToCm(value,originalValue) {
    let cmValue = value * 2.54;
    if (cmValue <= originalValue) {
        return Math.trunc(cmValue)
    } else {
        return Math.ceil(cmValue)
    } 
}
