import React from 'react';
import bigInt from "big-integer";

function LiquidMetricVisualizer({value}) {
    
    let magnitude = bigInt(1);
    let title = "mL"
    
    if (value >= 1000000000000000) {
        title = "cubic kilometers"
        magnitude = 1000000000000000
    }
    else if (value >= 2000000) {
        title = "cubic meters"
        magnitude = 1000000
    }
    else if (value >= 9000) {
        title = "gallons"
        magnitude = 4540;
    } else if (value > 1000) {
        title = "L"
        magnitude = 1000;
    }
    
    if( value > 1000000000000000) {
        value = bigInt(Math.floor(value))
        if (value >= 846786665000000000000000000000000000000000000000000000) {
            title = "cubic light year"
            magnitude = 846786665000000000000000000000000000000000000000000000
        }
    }
    return <span>
        {new Intl.NumberFormat().format((value / magnitude))} {title}
    </span>
}



export default LiquidMetricVisualizer;